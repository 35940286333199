import PropTypes from 'prop-types';
import React from "react";

import { Box, Link  } from "@material-ui/core";

import arrowIcon from './img/arrow-simple-right.svg';
import homeIcon from './img/home.svg';

Breadcrumbs.propTypes = {
  category: PropTypes.array,
};

Breadcrumbs.defaultProps = {
  category: [],
};

export default function Breadcrumbs({ category }) {
  const style = {
    breadcrumbs: {
      display: {
        xs: 'none',
        sm: 'none',
        lg: 'flex',
      },
      alignItems: 'center',
      padding: '12.5px 0',
      borderTop: '1px solid #DBDDE1',
      borderBottom: '1px solid #DBDDE1',
      marginBottom: '40px'
    },
    link: {
      textDecoration: 'none',

      '&:hover': {
        textDecoration: 'underline',
      }
    },
    item: {
      fontSize: '14px',
      color: '#6E7884'
    },
    seporator: {
      display: 'flex',

      '& > svg': {
        fontSize: '14px',
        margin: '0 10px',
        color: '#6E7884',
      },
    }
  };

  const seporator = () => {
    return (
      <Box sx={style.seporator}>
        <Box component="img" src={arrowIcon} />
      </Box>
    )
  }

  return (
    <Box className={'breadcrumbs'} sx={style.breadcrumbs}>
      <Link href="https://arenza.ru/" sx={ style.link } >
        <Box sx={{ ...style.item, display: 'flex', alignItems: 'center', }}>
          <Box alt={"Главная"} component="img" src={homeIcon} sx={{ marginRight: '10px' }} />
          Главная
        </Box>
      </Link>

      {seporator()}

      <Link href="/" sx={ style.link }>
        <Box sx={style.item}>Каталог</Box>
      </Link>

      {category && category.length > 0 && (
        category.map(elem => (
          <>
            {seporator()}

            <Link href={`/category/${elem.id}`} sx={ style.link }>
              <Box sx={style.item}>{elem.title}</Box>
            </Link>
          </>
        ))
      )}
    </Box>
  );
}